import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const OnderhoudPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/onderhoud/hero.jpg" }) {
        ...heroImage
      }
      onderhoud: file(
        relativePath: { eq: "images/onderhoud/services/onderhoud.jpg" }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Onderhoud - Uytterhaegen Jan BV" pathname="/onderhoud" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Onderhoud en herstellingen</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Onderhoud en herstellingen</h1>
                  <p>
                    Het spreekt voor zich dat verbrandingstoestellen onderhouden
                    moeten worden. Het kuisen en afstellen van de brander zorgt
                    voor een optimaal rendement en langere levensduur van uw
                    toestel.
                  </p>
                  <p>
                    Wij doen onderhoud en herstellingen op traditionele
                    toestellen van alle merken. Condensatie ketels beperken we
                    ons tot de meest gekende merken. Bij elk onderhoud wordt een
                    wettelijk attest afgeleverd.
                  </p>
                  <p>
                    Ook voor vervangen van kraanwerk zoals keuken-, bad-,
                    douche- of lavabokraan, of herstelling van leidingen kan je
                    beroep doen op ons vakmanschap.
                  </p>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.onderhoud.childImageSharp.name}
                    fluid={data.onderhoud.childImageSharp.fluid}
                  />
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OnderhoudPage
